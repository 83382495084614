.wrapper {
    background-color: #FFF5EF;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.innerWrapper {
    background-color: #FFF5EF;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
}

.eachColumn {
    display: flex;
    flex-direction: column;
    width: 18%;

    >h1 {
        font-family: Inter-Regular;
        font-size: 1.5vw;
        color: #14364B;
        text-align: left;
    }
}

.iconContainer {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    margin-top: 2.5%;
}

.iconRow {
    display: flex;
    flex-direction: row;
    align-items: start;

    >p {
        font-family: Inter-Regular;
        font-size: 1.2vw;
        color: #14364B;
        text-align: left;
        width: 45%;
        margin-left: 10%;
        margin-top: 0
    }

}

@media (max-width: 900px) {
    .wrapper {
        background-color: #FFF5EF;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .innerWrapper {
        background-color: #FFF5EF;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 1.5%;
        padding-bottom: 1.5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .eachColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 5%;

        >h1 {
            font-family: Inter-Regular;
            font-size: 4vw;
            color: #14364B;
            text-align: center;
        }
    }

    .iconContainer {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        width: 25%;
        justify-content: space-evenly;

    }

    .iconRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 1%;
        width: 50%;

        .numberWrapper {
            display: flex;
            align-items: center;
            justify-content: space-around;
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            margin-top: 8%;

            >p {
                align-items: center;
                justify-content: center;
                font-family: Inter-Regular;
                display: flex;
                font-size: 3vw;
                color: #14364B;
                text-align: right;
                width: 100%;
                margin-top: -8%;
            }
        }

        >p {

            display: flex;
            font-family: Inter-Regular;
            font-size: 3vw;
            color: #14364B;
            text-align: left;
            width: 100%;
        }



    }
}