.infoWrapper {
    margin-top: 3%;

    .eachInfo {
        width: 56vw;
        line-height: 2vh;
        padding-top: 2vh;

        >h1 {
            font-family: Merri-Bold;
            font-size: 2vw;
            color: #14364B;
            text-align: left;
            width: 100%;
        }

        >p {
            font-family: Inter-Light;
            font-size: 1vw;
            color: #14364B;
            text-align: left;
        }
    }
}

.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.imageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 33vw;
    width: 100%;
    margin-top: 10;

    >h1 {
        letter-spacing: 0.07em;
        font-family: Merri-Regular;
        font-size: 2.3vw;
        color: #14364B;
        width: 50%
    }

    >p {
        font-family: Inter-Light;
        letter-spacing: 0.02em;
        font-size: 1.2vw;
        color: #14364B;
        width: 37%;
    }
}

.line {
    height: 2px;
    width: 8%;
    background-color: #14364B;
    color: #14364B;
}

.inputWrapper {
    flex-direction: column;
    display: flex;
    width: 80%;
    height: 80vh;


    .nameWrapper {
        width: 100%;
        margin-bottom: 1.5%;
        justify-content: space-between;
        justify-content: left;
        height: 10%;

        .nameInputLeft {
            width: 34%;
            border-radius: 0;
            height: 100%;
            margin-bottom: 3%;
            // margin-left: -10%;
            border: 0.5px solid #14364B;
            padding-left: 10px;
            font-size: 1.9vh;
        }

        .nameInputRight {
            width: 34%;
            border-radius: 0;
            height: 100%;
            margin-bottom: 3%;
            margin-left: 2%;
            border: 0.5px solid #14364B;
            padding-left: 10px;
            font-size: 1.9vh;
        }
    }

    .normalInput {
        height: 10%;
        margin-bottom: 1.5%;
        border-radius: 0;
        border: 0.5px solid #14364B;
        padding-left: 10px;
        width: 70%;
        margin-left: 15%;
        font-size: 1.9vh;
    }

    .messageInput {
        height: 50%;
        border-radius: 0;
        border: 0.5px solid #14364B;
        padding-left: 10px;
        width: 70%;
        margin-left: 15%;
        font-size: 1.9vh;
    }
}

.submitButton {
    margin-bottom: 5%;
    margin-top: 0.5%;
    background-color: transparent;
    border: 1px solid white;
    align-self: flex-end;
    font-weight: 600;
    font-size: 1.2vw;
    margin-right: 22vw;

}

@media (max-width: 480px) {

    .wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: -3.1vh;
    }

    .imageWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        background-repeat: no-repeat;
        background-size: cover;
        height: 29vh;
        width: 100%;
        padding-left: 5vw;


        >h1 {
            letter-spacing: 0.07em;
            font-family: Merri-Regular;
            font-size: 7vw;
            width: 90%;
            color: #14364B;
            text-align: start;
        }

        >p {
            font-family: Inter-Light;
            letter-spacing: 0.02em;
            font-size: 3vw;
            color: #14364B;
            width: 80%;
            text-align: start;
        }
    }

    .infoWrapper {


        .eachInfo {
            width: 90vw;

            >h1 {
                font-family: Merri-Bold;
                font-size: 4vw;
                color: #14364B;
                text-align: left;
                width: 100%;
                // line-height: 5vh;
            }

            >p {
                font-family: Inter-Light;
                font-size: 3vw;
                color: #14364B;
                text-align: left;
            }
        }
    }

    .inputWrapper {
        flex-direction: column;
        display: flex;
        justify-content: center;
        width: 80%;
        height: 80vh;

        .normalInput {
            height: 10%;
            margin-bottom: 1.5%;
            border-radius: 0;
            border: 0.5px solid #14364B;
            padding-left: 10px;
            width: 100%;
            font-size: 1.9vh;
            margin-left: -0.05vw;
        }

        .messageInput {
            height: 50%;
            border-radius: 0;
            border: 0.5px solid #14364B;
            padding-left: 10px;
            width: 100%;
            font-size: 1.9vh;
            margin-left: -0.05vw;
        }
    }

    .submitButton {
        background-color: transparent;
        border: 1px solid white;
        align-self: flex-end;
        font-weight: 600;
        font-size: 4.2vw;
        margin-right: 8vw;
        margin-bottom: 15%;

    }
}