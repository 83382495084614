.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.imageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > h1 {
        margin-top: -1.5%;
        letter-spacing: 0.07em;
        font-family: Inter-Light;
        font-size: 1.2vw;
        color:white;
        width: 50%
    }

    > p {
        font-family: Merri-Light;
        letter-spacing: 0.02em;
        font-size: 1.7vw;
        color:white;
        width: 37%;
        margin-top: 18%;
    }

    > button {
        background-color: #14364B;
        color: white;
        border: none;
        width: 20vw;
        height: 5%;
        font-size: 1.2vw;
        font-family: Inter-Light;
        border-radius: 10px;
        font-weight: 600;
        margin-top: 1vh;
    }

    > button:hover {
        background-color: #899AA5;
        color: white;
        border: none;
        width: 20vw;
        height: 5%;
        font-size: 1.2vw;
        font-family: Inter-Light;
        border-radius: 10px;
        font-weight: 600;
        margin-top: 1vh;
        cursor: pointer;
    }

}

.cardWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
    margin-top: 23%;
}

.middleText {
    font-family: Merri-Bold;
    color: #14364B;
    width: 80%;
    font-size: 2.8vw;
    margin-top: 5%;
    margin-bottom: 5%;
}

.infoWrapper {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%;
}

.info {
    width: 47%;

    >h1 {
        font-family: Merri-Bold;
        color: #14364B;
        font-size: 2vw;
        text-align: left;
        width: 88%
    }

    >p {
        font-family: Inter-Regular;
        color: #14364B;
        font-size: 1.1vw;
        text-align: left;
        width: 100%
    }

    > li {
        font-family: Inter-Bold;
        color: #14364B;
        font-size: 1.3vw;
        text-align: left;
        width: 100%;
        margin-bottom: 2vh;
    }
}

.imageContainer {
    width: 47%;
    height: 100%
}

.button {
    background-color: #14364B;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 17vw;
    font-family: Inter-Light;
    border: none;
    height: 4vw;
    align-items: center;
    font-size: 1.2vw;
    margin-top: 7%;
}

.demoWrapper {
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10%;
    margin-top: 1%;
}

.vertical-fade-in-section {
    opacity: 0;
    transform: translateY(15vh);
    visibility: hidden;
    transition: opacity 2.6s ease-out, transform 2.2s ease-out;
    will-change: opacity, visibility;
  }
  .vertical-fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  

  @media (max-width: 900px) {
    .wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    
    .imageWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-size: auto;
        
    
        > h1 {
            margin-top: 0%;
            letter-spacing: 0.07em;
            font-family: Inter-Light;
            font-size: 2.2vw;
            color:white;
            width: 100%
        }
    
        > p {
            font-family: Merri-Light;
            letter-spacing: 0.02em;
            font-size: 4vw;
            color:white;
            width: 100%;
            margin-top: 25%;
        }
    
        > button {
            background-color: #14364B;
            color: white;
            border: none;
            width: 55vw;
            height: 40% !important;
            font-size: 2.8vw;
            font-family: Inter-Light;
            border-radius: 10px;
            font-weight: 600;
            margin-top: 1vh;
            padding-top: 1.3vh;
            padding-bottom: 1.3vh;
    
        }

        > button:hover {
            background-color: #899AA5;
            color: white;
            border: none;
            width: 55vw;
            height: 30% !important;
            font-size: 2.8vw;
            font-family: Inter-Light;
            border-radius: 10px;
            font-weight: 600;
            margin-top: 1vh;
            padding-top: 1vh;
            padding-bottom: 1vh;
        }
    }
    
    .cardWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 23%;
        margin-bottom: 10%;
    }
    
    .middleText {
        font-family: Merri-Bold;
        color: #14364B;
        width: 80%;
        font-size: 2.8vw;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    .infoWrapper {
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10%;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 150%;
    }
    
    .info {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 10%;
    
        >h1 {
            font-family: Merri-Bold;
            color: #14364B;
            font-size: 4vw;
            text-align: left;
            width: 100%
        }
    
        >p {
            font-family: Inter-Regular;
            color: #14364B;
            font-size: 3vw;
            text-align: left;
            width: 100%
        }
    
        > li {
            font-family: Inter-Bold;
            color: #14364B;
            font-size: 3vw;
            text-align: left;
            width: 100%;
            margin-bottom: 2vh;
        }
    }
    
    .imageContainer {
        width: 110%;
        height: 100%
    }
    
    .button {
        background-color: #14364B;
        color: white;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 65%;
        font-family: Inter-Light;
        border: none;
        height: 15vw;
        align-items: center;
        font-size: 5vw;
        margin-top: 7%;
    }
    
    .demoWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 15%;
        margin-top: 1%;
    }
    
    .vertical-fade-in-section {
        opacity: 0;
        transform: translateY(15vh);
        visibility: hidden;
        transition: opacity 2.6s ease-out, transform 2.2s ease-out;
        will-change: opacity, visibility;
      }
      .vertical-fade-in-section.is-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
      }
  }