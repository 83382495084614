.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;

    >h1 {
        font-family: Merri-Bold;
        font-size: 2vw;
        color: #14364B;
        margin-top: 8%;
        text-align: left;
        width: 87%;
        margin-bottom: 3%;
        padding-left: 8%;
    }
}

.imageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >h1 {
        letter-spacing: 0.07em;
        font-family: Merri-Regular;
        font-size: 2.3vw;
        color: #14364B;
        width: 50%
    }

    >p {
        font-family: Inter-Light;
        letter-spacing: 0.02em;
        font-size: 1.7vw;
        color: #14364B;
        width: 37%;
    }
}

.line {
    height: 2px;
    width: 8%;
    background-color: #14364B;
    color: #14364B;
}

.infoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 3%;
    padding-left: 10%;
    padding-right: 10%;

    .eachInfo {
        width: 40%;

        .eachInfoSub {
            text-align: left;

            >h1 {
                font-family: Merri-Regular;
                letter-spacing: 0.02em;
                font-size: 2vw;
                color: #14364B;
                text-align: left
            }

            >p {
                font-family: Inter-Light;
                letter-spacing: 0.02em;
                font-size: 1.2vw;
                color: #14364B;
                text-align: left
            }
        }
    }
}

.projectWrapper {
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 4%;
    padding-left: 10%;
    padding-right: 10%;
}

.button {
    background-color: #14364B;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 20vw;
    font-family: Inter-Light;
    border: none;
    height: 6vw;
    align-items: center;
    font-size: 1.2vw;
    margin-bottom: 6%;
}

@media (max-width: 480px) {
    .wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: -4.3vh;

        >h1 {
            font-family: Merri-Bold;
            font-size: 4.5vw;
            color: #14364B;
            margin-top: 5%;
            text-align: left;
            width: 100%;
            margin-bottom: 3%;
        }

    }

    .imageWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        background-repeat: no-repeat;
        background-size: cover;
        height: 29vh !important;
        width: 100%;
        padding-left: 5vw;

        >h1 {
            letter-spacing: 0.07em;
            font-family: Merri-Regular;
            font-size: 7vw;
            width: 90%;
            color: #14364B;
            text-align: start;
        }

        >p {
            font-family: Inter-Light;
            letter-spacing: 0.02em;
            font-size: 3vw;
            color: #14364B;
            width: 80%;
            text-align: start;
        }
    }

    .line {
        height: 2px;
        width: 8%;
        background-color: #14364B;
        color: #14364B;
    }

    .infoWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;

        .eachInfo {
            width: 100%;
            display: flex;
            flex-direction: row;

            .eachInfoSub {
                width: 50%;

                >h1 {
                    font-family: Merri-Regular;
                    letter-spacing: 0.02em;
                    font-size: 4vw;
                    color: #14364B;
                    text-align: left
                }

                >p {
                    font-family: Inter-Light;
                    letter-spacing: 0.02em;
                    font-size: 2.5vw;
                    color: #14364B;
                    text-align: left
                }
            }


        }

        .images {
            // width: 50%;
            // max-width: 100vw;
            width: 100vw;
            margin: 0 auto;

            // display: flex;

            .image-gallery {
                width: 100vw;
                height: auto;
            }

            .image-gallery-slide img {
                width: 100%;
                height: auto;
                max-height: 80vh;
                object-fit: cover;
                overflow: hidden;
                object-position: center center;
            }

            .fullscreen .image-gallery-slide img {
                max-height: 100vh;
            }
        }
    }



    .projectWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 4%;
        // padding-left: 10%;
        // padding-right: 10%;
    }

    .button {
        background-color: #14364B;
        color: white;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 50%;
        font-family: Inter-Light;
        border: none;
        height: 15vw;
        align-items: center;
        font-size: 5vw;
        margin-top: 7%;
        margin-bottom: 15%;
    }
}