.wrapper {
    display: flex;
    flex-direction: column;
    height: 32vw;
    width: 28vw;
    background-color: white;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
    align-items: center;
    padding-bottom: 2vw;
    padding-top: 2vw;
    justify-content: start;

    >h1 {
        font-family: Merri-Bold;
        color: #14364B;
        font-size: 1.5vw;
    }

    >p {
        font-family: Inter-Regular;
        width: 80%;
        font-size: 1.1vw;
    }
}

.listItem {
    font-family: Inter-Regular;
    width: 100%;
    font-size: 1.1vw;
    list-style-type: none;
}

.line {
    height: 1px;
    width: 73%;
    background-color: #14364B;
    color: #14364B;
}

.spacer {
    // height: 8%
}

@media (max-width: 900px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 70vw;
        background-color: white;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
        align-items: center;
        padding-bottom: 5vw;
        padding-top: 5vw;
        justify-content: start;
        margin-bottom: 10%;
    
        >h1 {
            font-family: Merri-Bold;
            color: #14364B;
            font-size: 3.5vw;
        }
    

    }
    
    .listItem {
        font-family: Inter-Regular;
        width: 100%;
        font-size: 2.5vw;
        list-style-type: none;
    }
    
    .line {
        height: 1px;
        width: 73%;
        background-color: #14364B;
        color: #14364B;
    }
    
    .spacer {
        // height: 8%
    }
}